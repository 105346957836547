import {Accordion, Box, Container, Heading, Image, SimpleGrid, Stack, Text} from '@chakra-ui/react'
import {JobPosting} from './JobPosting'
// @ts-ignore
import image from '../../assets/happycouple.webp'
import {jobListings} from "./data";

export const CareersWithAccordion = () => (
    <Container py={{base: '16', md: '24'}} minW={'full'}>
        <Box maxW={{base: 'xl', md: '7xl'}} mx={'auto'}>
            <SimpleGrid columns={{base: 1, md: 2}} gap={12}>
                <Stack spacing={{base: '4', md: '6'}}>
                    <Stack spacing={2}>
                        <Heading as="h4" fontSize="22px">Embark on a Journey of Compassionate Connection with
                            Sultry</Heading>
                        <Text>
                            In a world where instant gratification often overshadows genuine connection, Sultry stands
                            out by offering a sanctuary for those seeking to foster relationships grounded in patience
                            and emotional depth. For individuals experiencing erectile dysfunction, P.I.E.D., or simply
                            those who prefer the slow bloom of intimacy, Sultry is not just a dating app, it's a
                            movement towards a more empathetic approach to love and relationships.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Heading as="h4" fontSize="22px">Building Trust and Intimacy at Every Step</Heading>
                        <Text>
                            At Sultry, we understand that emotional bonds form the foundation of a healthy sex life.
                            With that in mind, our platform is designed to encourage connections that respect each
                            individual's pace towards physical intimacy. By fostering a community where trust is
                            paramount, Sultry ensures that every conversation, date, and encounter is an investment in a
                            future where sexual wellbeing is achieved through mutual understanding and support.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Heading as="h4" fontSize="22px">Inclusive Dating: A Safe Haven for All Journeys</Heading>
                        <Text>
                            Whether you're here to find love, support, or understanding, Sultry welcomes you with open
                            arms. Our members come from all walks of life, each with their unique experiences and
                            perspectives on intimacy. This diversity is our strength, ensuring that everyone can find a
                            partner who truly resonates with them. Our community is a tapestry of stories, all united in
                            the belief that every person deserves a fulfilling, shame-free, and healthy sex life.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Heading as="h4" fontSize="22px">Navigate Dating with Confidence and Security</Heading>
                        <Text>
                            We recognize that security and privacy are crucial when it comes to online dating. Sultry is
                            built on a foundation of trust and respect, utilizing state-of-the-art security measures to
                            protect your personal information. Navigate the path to love with the confidence that your
                            journey is safe, your conversations are private, and your right to confidentiality is always
                            respected.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Heading as="h4" fontSize="22px">Discover the Sultry Difference: Where Patience Meets
                            Passion</Heading>
                        <Text>
                            Sultry is redefining the dating landscape by emphasizing the beauty of taking things slow.
                            Our platform is tailored for those who appreciate the gradual unfolding of romance and the
                            buildup of desire. We believe that a patient approach to intimacy leads to a more profound
                            and passionate connection, paving the way for a sex life that is both satisfying and
                            sustainable.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Heading as="h4" fontSize="22px">Real Connections, Real Support: Your Partner in the Journey to
                            Intimacy</Heading>
                        <Text>
                            Joining Sultry means gaining a partner in your journey to intimacy. Our resources,
                            community, and expert advice are geared towards understanding and overcoming the challenges
                            of ED and P.I.E.D. We provide a space where questions are answered, experiences are shared,
                            and victories are celebrated, all contributing to your growth towards a rewarding sex life.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Heading as="h4" fontSize="22px">A New Era of Emotional and Physical Intimacy</Heading>
                        <Text>
                            We invite you to be part of a new era, one where the emotional and physical aspects of
                            intimacy are balanced and enriched through patience and care. Sultry is not just about
                            finding a date; it's about crafting a new chapter in your life where emotional connection
                            and sexual health are intertwined, leading to a deeper, more fulfilling form of intimacy.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Heading as="h4" fontSize="22px">Your Pathway to a Supportive and Understanding
                            Relationship</Heading>
                        <Text>
                            With Sultry, you'll find pathways to relationships that flourish on mutual support and
                            understanding. Our platform is a testament to our commitment to helping you build the kind
                            of partnership where challenges are faced together, and triumphs are shared, creating a bond
                            that extends beyond the physical into the realms of supportive and enduring love.
                        </Text>
                    </Stack>
                </Stack>
                <Accordion defaultIndex={0}>
                    <Image src={image} w={'full'}/>
                    <Heading as="h4" fontSize="26px" mb={6} mt={10}>FAQ</Heading>
                    {jobListings.map((listing, id) => (
                        <JobPosting key={id} {...listing} />
                    ))}
                </Accordion>
            </SimpleGrid>
        </Box>
    </Container>
)
