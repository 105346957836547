import {Box, Button, Heading, LightMode, SimpleGrid, Stack, Text, useColorModeValue as mode,} from '@chakra-ui/react'
import * as Logos from './Brands'
import {ArrowForwardIcon} from "@chakra-ui/icons";

export const WithScreenshotBelow = ({heading, subheading, onOpen, hideIcons}: {
    heading: string,
    subheading: string,
    imgSrc?: string
    hideIcons?: boolean,
    onOpen: any
}) => {
    return (
        <Box>
            <Box as="section" color="white" py="7.5rem">
                <Box maxW={{base: 'xl', md: '6xl'}} mx="auto" px={{base: '6', md: '8'}}>
                    <Box textAlign="center">
                        <Heading
                            as="h1"
                            size="3xl"
                            fontWeight="extrabold"
                            maxW="6xl"
                            mx="auto"
                            lineHeight="1.2"
                            letterSpacing="tight"
                        >
                            {heading}
                        </Heading>
                        <Text fontSize="xl" mt="4" maxW="5xl" mx="auto">
                            {subheading}
                        </Text>
                    </Box>

                    <Stack
                        justify="center"
                        direction={{base: 'column', md: 'row'}}
                        mt="10"
                        spacing="4"
                    >
                        <LightMode>
                            <Button size="lg" bg={'black'} border={'1px solid white'} color={'white'} px="8"
                                    fontWeight="bold" fontSize="md" rightIcon={<ArrowForwardIcon/>}
                                    _hover={{color: 'black', bg: 'white'}} onClick={onOpen}>
                                Join our growing community
                            </Button>
                            {/*<Button size="lg" colorScheme="whiteAlpha" px="8" fontWeight="bold" fontSize="md">*/}
                            {/*    Request demo*/}
                            {/*</Button>*/}
                        </LightMode>
                    </Stack>
                </Box>
            </Box>
            {!hideIcons &&
                <Box as="section" pb="24">
                    <Box maxW={{base: 'xl', md: '7xl'}} mx="auto" px={{base: '6', md: '8'}}>
                        <Text
                            fontWeight="bold"
                            fontSize="sm"
                            textAlign="center"
                            textTransform="uppercase"
                            letterSpacing="wide"
                            color={mode('gray.600', 'gray.400')}
                        >
                            Trusted by over 6,000 men
                        </Text>
                        <SimpleGrid
                            mt="8"
                            columns={{base: 1, md: 2, lg: 6}}
                            color="gray.500"
                            alignItems="center"
                            justifyItems="center"
                            spacing={{base: '12', lg: '24'}}
                            fontSize="2xl"
                        >
                            <Logos.ChatMonkey/>
                            <Logos.Wakanda/>
                            <Logos.Lighthouse/>
                            <Logos.Plumtic/>
                            <Logos.WorkScout/>
                            <Logos.Finnik/>
                        </SimpleGrid>
                    </Box>
                </Box>
            }
        </Box>
    )
}
