import {Box, Container, Heading, SimpleGrid, Stack, Text} from '@chakra-ui/react'
import {BlogPost} from './BlogPost'
import {posts} from './data'

export const BlogWithHeroImage = () => (
    <Box bg="white">
        <Box bg="blue.800" color="white">
            <Container pt={{base: '16', md: '24'}} pb={{base: '32', md: '48'}} maxW={'7xl'}>
                <Stack spacing={{base: '8', md: '10'}} align="center">
                    <Stack spacing={{base: '4', md: '6'}} textAlign="center" mx={'auto'}>
                        <Stack spacing="4">
                            <Heading size={{base: '2xl', md: '4xl'}}>Revolutionize Your Sleep: The Ultimate Bed Divider for Couples</Heading>
                        </Stack>
                        <Text fontSize={{base: 'xl', md: 'xl'}} maxW="2xl" mx={'auto'}>
                            Discover the Secret to Uninterrupted Sleep and Enhanced Privacy in Shared Beds – Transform
                            Your Nights with Our Innovative Bed Divider Solution!
                        </Text>
                    </Stack>
                </Stack>
            </Container>
        </Box>
        <Container pb={{base: '16', md: '24'}} mt={{base: '-16', md: '-24'}} maxW={'7xl'}>
            <Stack spacing={{base: '16', md: '24'}}>
                <Stack spacing={{base: '12', md: '16'}}>
                    <BlogPost post={posts[0]} isHero/>
                    <SimpleGrid columns={{base: 1, md: 2, lg: 3}} gap={{base: '12', lg: '8'}}>
                        {posts.slice(1, 4).map((post) => (
                            <BlogPost key={post.id} post={post}/>
                        ))}
                    </SimpleGrid>
                </Stack>
            </Stack>
        </Container>
    </Box>
)
