import image from "../../assets/BedDivider_ProductBanner_Grey.webp";
import image2 from "../../assets/BedDivider_Lifestyle-_Grey.webp";
import image3 from "../../assets/BedDivider_Lifestyle-_DarkGrey.webp";
import image4 from "../../assets/BedDivider_Lifestyle-_White.webp";

export const posts = [
    {
        "id": "1",
        "title": "Embracing Quiet Nights: How Bed Dividers Help Reduce Noise Disturbances",
        "excerpt": "Discover the role of Bed Dividers in creating a quieter sleeping environment. Ideal for light sleepers or those with snoring partners, this post explores how a simple addition to your bed can significantly reduce noise disturbances, leading to a deeper and more restful sleep.",
        "image": image,
        "url": "/embracing-quiet-nights",
        "tags": [
            {"label": "Sleep Health", "color": "blue"},
            {"label": "Relationships", "color": "pink"}
        ],
        "publishedAt": "January 28, 2023",
        "author": {
            "name": "Busola Banks",
            "avatarUrl": "https://tinyurl.com/2p8fy9ym"
        }
    },
    {
        "id": "2",
        "title": "Couple's Guide to Better Sleep: Choosing the Right Bed Divider",
        "excerpt": "Learn how the right divider can create a personal sleep sanctuary, fostering uninterrupted rest maintaining the closeness and warmth of sharing a bed. Mitigating disturbances caused by different sleep patterns, the perfect bed divider can enhance your sleep quality. ",
        "image": image2,
        "url": "/couples-guide-to-better-sleep",
        "tags": [
            {"label": "Sleep Tips", "color": "green"},
            {"label": "Product Reviews", "color": "red"}
        ],
        "publishedAt": "December 29, 2022",
        "author": {
            "name": "Samy Tom",
            "avatarUrl": "https://tinyurl.com/2p8h98w8"
        }
    },
    {
        "id": "3",
        "title": "Navigating Nighttime Allergies: A Bed Divider’s Role in Healthier Sleep",
        "excerpt": "For those struggling with allergies, this post highlights how a Bed Divider can be a game-changer. Learn about the benefits of creating a personal, allergen-reduced space in bed, contributing to a more comfortable and uninterrupted night's sleep.",
        "image": image3,
        "url": "/allergies-and-a-healthier-sleep",
        "tags": [{"label": "Relationship Advice", "color": "blue"}],
        "publishedAt": "November 30, 2022",
        "author": {
            "name": "Angelina Gates",
            "avatarUrl": "https://tinyurl.com/2p98t7nh"
        }
    },
    {
        "id": "4",
        "title": "Sleep Hygiene 101: The Role of Bed Dividers in a Healthy Sleep Routine",
        "excerpt": " Dive into the world of sleep hygiene and discover the unexpected benefits of using a Bed Divider. This post will guide you through the ways a Bed Divider can contribute to a more structured and restful sleep environment, enhancing overall health.",
        "url": "/sleep-hygiene-101",
        "image": image4,
        "tags": [{"label": "Interior Design", "color": "yellow"}],
        "publishedAt": "October 31, 2022",
        "author": {
            "name": "Busola Banks",
            "avatarUrl": "https://tinyurl.com/2p8fy9ym"
        }
    }
]


type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType>
    ? ElementType
    : never

export type Post = ElementType<typeof posts>
