import React from 'react';
import './App.css';
import LogRocket from 'logrocket';
import {Home} from "./pages/Home";
import {Blog} from "./pages/Blog";
import {Contact} from "./pages/Contact";
import {Route, Routes} from "react-router-dom";
import {NoMatch} from "./pages/404";
import {SleepHygiene101} from "./pages/SleepHygiene101";
import {posts} from "./components/BlogWithHeroImage/data";
import {CouplesGuideToBetterSleep} from "./pages/CouplesGuideToBetterSleep";
import {EmbracingQuietNights} from "./pages/EmbracingQuietNights";
import {AllergiesHealthySleep} from "./pages/AllergiesHealthySleep";

LogRocket.init('4zf1vv/taking-it-slow');

function App() {
    return (
        <Routes>
            <Route index element={<Home/>}/>
            {/*<Route path="about" element={<About />} />*/}
            <Route path="blog" element={<Blog/>}/>
            <Route path="contact" element={<Contact/>}/>
            <Route path="blog/sleep-hygiene-101"
                   element={<SleepHygiene101 title={posts[3].title} excerpt={posts[3].excerpt}/>}/>
            <Route path="blog/embracing-quiet-nights"
                   element={<EmbracingQuietNights title={posts[0].title} excerpt={posts[0].excerpt}/>}/>
            <Route path="blog/couples-guide-to-better-sleep"
                   element={<CouplesGuideToBetterSleep title={posts[1].title} excerpt={posts[1].excerpt}/>}/>
            <Route path="blog/allergies-and-a-healthier-sleep"
                   element={<AllergiesHealthySleep title={posts[2].title} excerpt={posts[2].excerpt}/>}/>
            <Route path="*" element={<NoMatch/>}/>
        </Routes>
    );
}


export default App;
