import {NavbarWithPopoverOnAccent} from "../components/NavbarWithPopoverOnAccent/NavbarWithPopoverOnAccent";
import SEO from "../components/SEO/Seo";
import {WithImageBackground} from "../components/WithImageBackground/WithImageBackground";
import {ImageText} from "../components/ImageText/ImageText";
import image3 from "../assets/takingitslow2.webp";
import image5 from "../assets/takingitslow4.webp";
import image from "../assets/takingitslow3.webp";
import infographic from "../assets/chart.webp";
import {FooterWithSocialIcons} from "../components/FooterWithSocialIcons/FooterWithSocialIcons";
import {PopupWithDiscount} from "../components/PopupWithDiscount/PopupWithDiscount";
import React, {useState} from "react";
import {Box, Container, Heading, Image, Stack, Text, useDisclosure} from "@chakra-ui/react";
import {WithScreenshotBelow} from "../components/WithScreenshotBelow/App";
import {WithImageBackgroundTextLeft} from "../components/WithImageBackgroundTextLeft/WithImageBackgroundTextLeft";
import {CareersWithAccordion} from "../components/CareersWithAccordion/CareersWithAccordion";

export const Home = () => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [color] = useState('')
    const [size] = useState('')
    return (
        <Box>
            <NavbarWithPopoverOnAccent overlay={true}/>
            <SEO title={'Sultry | Compassionate Dating For Men'}/>
            <WithImageBackground onOpen={onOpen} useH1={true}/>
            <WithScreenshotBelow heading={`Your privacy and comfort are paramount.`}
                                 subheading={`We've created a secure environment with robust privacy controls, ensuring your experience is safe and respectful. Our inclusive platform welcomes everyone who upholds our values of patience, understanding, and kindness.`}
                                 imgSrc={image} onOpen={onOpen} hideIcons={true}/>
            <WithImageBackgroundTextLeft heading={'Understanding Meets Connection'}
                                         subheading={'We\'re redefining the dating experience. Our platform is a welcoming community for men dealing with P.I.E.D., and for those who believe in the power of patience and empathy in relationships. Here, it\'s not just about finding a partner; it\'s about finding someone who understands and respects your journey.'}
                                         imgSrc={image5} onOpen={onOpen}/>
            <ImageText img={infographic} heading={`A Community of Support and Acceptance`} onOpen={onOpen}
                       orderBase={'column'}
                       objectFit={'contain'}
                       orderMd={'row'}
                       subheading={`We recognize the challenges that come with P.I.E.D. and the importance of a supportive partner in overcoming them. Our platform is more than just a space for meeting others; it's a community where you can be open about your experiences without fear of judgment. We bring together individuals who are not only compassionate but also share a common goal – to build a relationship that thrives on understanding and patience.`}
            />

            <Box bg="bg.surface" minW={'full'}>
                <Container py={{base: '16', md: '24'}} maxW={{base: 'xl', md: '7xl'}} mx={'auto'}>
                    <Stack
                        direction={{base: "column", md: "row-reverse"}}
                        spacing="16"
                        align={{base: 'start', md: 'center'}}
                    >
                        <Stack spacing={{base: '8', md: '10'}} width="full">
                            <Stack spacing={{base: '4', md: '6'}}>
                                <Heading size={{base: 'lg', md: 'lg'}} as={'h2'}>Did you know that half of the
                                    millennial and Gen Z men face psychological sexual
                                    issues?</Heading>
                                <Text fontSize={{base: 'lg', md: 'xl'}} color="fg.muted">
                                    This is nothing to be ashamed of, but a shared human experience. At Sultry,
                                    we're committed to dispelling the stigma and reinforcing the message—you are not
                                    alone.
                                </Text>
                                <Text fontSize={{base: 'lg', md: 'xl'}} color="fg.muted">
                                    Founded by someone who stood in these shoes, Sultry is a beacon for
                                    those seeking to address these intimate challenges. Our mission is to help
                                    individuals confront these issues head-on, to foster happier, more fulfilling lives.
                                </Text>
                            </Stack>
                        </Stack>
                        <Box width="full" height={{base: 'sm', md: 'md'}}>
                            <Image
                                boxSize="full"
                                alt={'about-us'}
                                src={image3}
                                objectFit="cover"
                            />
                        </Box>
                    </Stack>
                </Container>
            </Box>


            {/*<ImageCta onOpen={onOpen} heading={'Find Your Patient Partner'}*/}
            {/*          subheading={'The path to resolving P.I.E.D. can be smoother with a partner who stands by you. With Sultry, you\'ll meet people who are ready to walk this path with you. Our members value deep connections and are willing to grow and learn together. Whether you\'re looking for love, friendship, or support, you\'ll find a community here that\'s ready to embrace you as you are.'}*/}
            {/*          image={image} bg={'blackAlpha.600'}/>*/}

            {/*<CareersWithAccordion/>*/}
            {/*<ImageCta onOpen={onOpen} heading={'Safe, Respectful, and Inclusive Connections'}*/
            }
            {/*          subheading={'Your privacy and comfort are paramount. We\'ve created a secure environment with robust privacy controls, ensuring your experience is safe and respectful. Our inclusive platform welcomes everyone who upholds our values of patience, understanding, and kindness.'}*/
            }
            {/*          image={image2} bg={'blackAlpha.600'}/>*/
            }
            <CareersWithAccordion/>
            <WithScreenshotBelow heading={`Compassionate Dating Starts Here`}
                                         subheading={`Join us at Sultry and discover a world where relationships start with empathy and grow with mutual support. Sign up now and take the first step towards a connection that appreciates the importance of taking things slow and solving challenges together.`}
                                         imgSrc={image3} hideIcons={true} onOpen={onOpen}/>

            <FooterWithSocialIcons/>
            <PopupWithDiscount isOpen={isOpen} onClose={onClose} color={color} size={size}/>
        </Box>
    )
}