import {Box, Button, Flex, Heading, Img, Stack, Text} from '@chakra-ui/react'
// @ts-ignore
import image from '../../assets/takingitslow1.webp'
import React from "react";
import {ArrowForwardIcon} from "@chakra-ui/icons";

export const WithImageBackground = ({onOpen, useH1}: { onOpen: any, useH1?: boolean }) => {
    const handleBuyNowClick = () => {
        // Assuming onOpen is some function you want to run when the button is clicked
        onOpen();

        // Pushing an event to GTM's data layer
        if ((window as any).dataLayer) {
            (window as any).dataLayer.push({
                event: 'buy_now_clicked', // Event name. This is what GTM will listen for
                product: 'Ultimate Couples Bed Divider', // Optional: Any additional data you want to send
                // ... you can add more properties here
            });
        }
    };

    return (
        <Box bg="gray.800" as="section" position="relative">
            <Box py={{base: 16, lg: 32}} pb={{base: 8, lg: 32}} position="relative" zIndex={1} minH="740px"
                 display="flex" alignItems="center" maxW={'full'}>
                <Box px={{base: '6', md: '8'}} color="white" maxW={'7xl'} mx={'auto'} w={'7xl'}>
                    <Box maxW={{base: 'xl', md: '6xl'}} mx={'auto'} pt={24}>
                        {/*<Logo zIndex={'10000'} pos={'absolute'} top={'10px'} left={'10px'} maxW={'300px'}/>*/}
                        <Heading as={useH1 ? 'h1' : 'h2'} size="lg" fontWeight="bold" maxW="3xl">
                            There's a significant gap in how healthy young men address erection challenges, and Sultry
                            is here to revolutionize that. We're dedicated to connecting these men with compassionate
                            partners who understand the journey and are committed to collaboratively overcoming these
                            obstacles.
                        </Heading>
                        <Text fontSize={{md: 'md'}} mt="4" maxW="3xl">
                            Many turn to quick-fix solutions without addressing the core of their sexual wellbeing. At
                            Sultry, we embrace a more holistic approach – facilitating genuine connections with partners
                            who prioritize patience, understanding, and mutual support in overcoming challenges
                            together.
                        </Text>
                        <Stack direction={{base: 'column', md: 'row'}} mt="10" spacing="4">
                            <Button
                                bg="transparent"
                                border={'1px solid white'}
                                color={'white'}
                                px="24"
                                py="7"
                                rounded="full"
                                size="lg"
                                fontSize="1.5rem"
                                fontWeight="bold"
                                _hover={{
                                    bg: 'blue.800',
                                    cursor: 'pointer'
                                }}
                                onClick={handleBuyNowClick}
                                rightIcon={<ArrowForwardIcon css={`
                                    position: absolute;
                                    right: 8px;
                                    top: 5px;
                                    background: #d3bc0e;
                                    border-radius: 50%;
                                    color: black;
                                    width: 45px;
                                    font-size: 12px;
                                    height: 45px;
                                    text-align: center;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 8px;
                                `}/>}
                            >
                                Sign Up Now
                            </Button>
                            {/*<HStack*/}
                            {/*    transition="background 0.2s"*/}
                            {/*    justify={{base: 'center', md: 'flex-start'}}*/}
                            {/*    color="white"*/}
                            {/*    rounded="full"*/}
                            {/*    fontWeight="bold"*/}
                            {/*    px="6"*/}
                            {/*    py="3"*/}
                            {/*    _hover={{bg: 'whiteAlpha.300'}}*/}
                            {/*    onClick={handleJoinListClick}*/}
                            {/*>*/}
                            {/*    <span>Join the list</span>*/}
                            {/*    <HiChevronRight/>*/}
                            {/*</HStack>*/}
                        </Stack>
                    </Box>
                </Box>
            </Box>
            <Flex
                id="image-wrapper"
                position="absolute"
                insetX="0"
                insetY="0"
                w="full"
                h="full"
                overflow="hidden"
                align="center"
            >
                <Box position="relative" w="full" h="full">
                    <Img
                        src={image}
                        alt="Bed Divider For Couples"
                        w="full"
                        h="full"
                        objectFit="cover"
                        objectPosition="66%"
                        position="absolute"
                    />
                    <Box position="absolute" w="full" h="full" bg="blackAlpha.800"/>
                </Box>
            </Flex>
        </Box>
    )
}
