import {
    Box,
    Button,
    Container, Divider,
    Heading,
    Link,
    ListItem,
    Stack,
    Text,
    UnorderedList,
    useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import {NavbarWithPopoverOnAccent} from "../components/NavbarWithPopoverOnAccent/NavbarWithPopoverOnAccent";
import {ArrowLeftIcon} from "@chakra-ui/icons";

export const CouplesGuideToBetterSleep = ({title, excerpt}: { title: string, excerpt: string }) => {
    return (
        <Box bg="white">
            <NavbarWithPopoverOnAccent/>
            <Box bg="blue.800" color="white">
                <Container pt={{base: '16', md: '24'}} pb={{base: '32', md: '32'}} maxW={'7xl'}>
                    <Stack spacing={{base: '8', md: '10'}} align="center">
                        <Stack spacing={{base: '4', md: '6'}} textAlign="center" mx={'auto'}>
                            <Stack spacing="4">
                                <Heading size={{base: '2xl', md: '4xl'}}>{title}</Heading>
                            </Stack>
                            <Text fontSize={{base: 'xl', md: 'xl'}} maxW="2xl" mx={'auto'}>
                                {excerpt}
                            </Text>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
            <Box>
                <Container pt={{base: '16', md: '24'}} pb={{base: '32', md: '32'}} maxW={'7xl'}>
                    <Stack spacing="8">
                        <Stack
                            spacing="5"
                            lineHeight="1.75"
                            maxW="7xl"
                            color={useColorModeValue('blackAlpha.800', 'whiteAlpha.800')}
                        >
                            <Text fontSize="lg" mt={4}>
                                For couples, sharing a bed is a symbol of intimacy and togetherness. However, differing
                                sleep patterns and habits can often disturb this harmonious setup. The right bed divider
                                can be a transformative solution, offering a personal sleep sanctuary while maintaining
                                the closeness of sharing a bed. In this comprehensive guide, we’ll explore how choosing
                                the perfect bed divider can significantly enhance your sleep quality and strengthen your
                                relationship.
                            </Text>
                            <Divider my={6}/>
                            <Heading as="h2" size="lg" mb={4}>
                                Understanding the Importance of Individual Sleep Spaces
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                Sleep is a highly personal activity, and everyone has unique needs and preferences. For
                                some, the slightest movement or sound can disrupt sleep, while others may not be as
                                sensitive. A bed divider provides a simple yet effective solution to these challenges,
                                allowing each person to have their individual space without feeling disconnected.
                            </Text>
                            <Heading as="h2" size="lg" mb={4}>
                                The Role of a Bed Divider in Enhancing Sleep Quality
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                A bed divider does more than just separate; it creates a zone of comfort and
                                tranquility. It can mitigate disturbances like snoring, tossing and turning, and
                                different sleep schedules. By doing so, it ensures that both partners enjoy deeper, more
                                restorative sleep.
                            </Text>
                            <UnorderedList mb={4}>
                                <ListItem>Reduces sleep disturbances from partner movement or noise</ListItem>
                                <ListItem>Allows for different sleep schedules and habits</ListItem>
                                <ListItem>Creates a sense of individual space while sharing a bed</ListItem>
                            </UnorderedList>
                            <Heading as="h2" size="lg" mb={4}>
                                How to Choose the Right Bed Divider for You and Your Partner
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                Selecting the right bed divider involves considering various factors such as size,
                                material, and ease of installation. It’s important to choose a divider that complements
                                your bedroom’s aesthetics while fulfilling its functional purpose.
                            </Text>
                            <UnorderedList mb={4}>
                                <ListItem>Consider the height and width of the divider for optimal separation and
                                    comfort.</ListItem>
                                <ListItem>Look for materials that provide the right balance between privacy and
                                    breathability.</ListItem>
                                <ListItem>Choose a design that blends seamlessly with your bedroom decor.</ListItem>
                            </UnorderedList>
                            <Heading as="h2" size="lg" mb={4}>
                                The Benefits of a Bed Divider in a Relationship
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                Beyond improving sleep quality, a bed divider can positively impact your relationship.
                                It respects individual sleep needs while maintaining the intimacy of sharing a bed,
                                leading to fewer disturbances and more harmonious mornings.
                            </Text>
                            <UnorderedList mb={4}>
                                <ListItem>Enhances mutual respect for personal space and sleep preferences</ListItem>
                                <ListItem>Reduces potential conflicts arising from sleep disturbances</ListItem>
                                <ListItem>Supports better mood and well-being for both partners</ListItem>
                            </UnorderedList>
                            <Heading as="h2" size="lg" mb={4}>
                                Making the Most of Your Bed Divider
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                To fully benefit from a bed divider, it’s essential to integrate it thoughtfully into
                                your sleep routine. Experiment with placement and adjust as needed to find the perfect
                                setup that suits both you and your partner.
                            </Text>
                            <Heading as="h2" size="lg" mb={4}>
                                Embracing a New Era of Restful Sleep
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                In conclusion, a bed divider can be a valuable addition to any couple’s bedroom. By
                                providing personal space and reducing sleep disturbances, it paves the way for
                                healthier, more enjoyable sleep experiences and a stronger, more understanding
                                relationship. Embrace the change and enjoy the journey to better sleep and a happier
                                partnership.
                            </Text>
                        </Stack>
                    </Stack>
                    <Button as={Link} href={'/blog'} leftIcon={<ArrowLeftIcon/>} mt={5} bg={'blue.700'} color={'white'}
                            _hover={{bg: 'blue.700', textDecoration: 'none'}}>Back to Blog</Button>
                </Container>
            </Box>
        </Box>
    )
}