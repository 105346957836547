import {Box, Button, Container, Heading, Link, Stack, Text} from '@chakra-ui/react'
import {NavbarWithPopoverOnAccent} from "../components/NavbarWithPopoverOnAccent/NavbarWithPopoverOnAccent";
import React from "react";
import {ArrowLeftIcon} from "@chakra-ui/icons";

export const NoMatch = () => (
    <Box>
        <NavbarWithPopoverOnAccent/>
        <Container py={{base: '16', md: '24'}}>
            <Stack spacing={{base: '8', md: '10'}} align="center" maxW="3xl" mx="auto">
                <Stack spacing={{base: '4', md: '5'}} textAlign="center">
                    <Heading size={{base: 'md', md: 'lg'}}>Page Not Found</Heading>
                    <Text fontSize={{base: 'lg', md: 'xl'}} color="fg.muted">
                        Not finding what you're looking for? Please go back to our homepage for more information.
                    </Text>
                </Stack>
                <Stack direction={{base: 'column', md: 'column'}} width="full" maxW={{md: 'lg'}} spacing="4">
                    <Button size="xl" px={5} height={'3rem'} as={Link} href={'/'} bg={'blue.800'}
                            leftIcon={<ArrowLeftIcon/>} _hover={{color: 'grey.600', textDecoration: 'none'}}
                            color={'white'}>Back to Home</Button>
                </Stack>
            </Stack>
        </Container>
    </Box>
)