import {Box, Container, Heading, Stack, Text,} from '@chakra-ui/react'
import {FormDataProvider} from "../../context/formDataContext";
import {SubscribeForm} from "../PopupWithDiscount/SubscribeForm";
import React from "react";

export const Newsletter = ({onClose}: { onClose: any }) => (
    <Container py={{base: '16', md: '24'}}>
        <Stack spacing={{base: '8', md: '10'}} align="center" mx="auto" maxW={'5xl'} w={'full'}>
            <Stack spacing={{base: '4', md: '5'}} textAlign="center">
                <Heading size={{base: 'md', md: 'lg'}}>Contact Us</Heading>
                <Text fontSize={{base: 'lg', md: 'xl'}} color="fg.muted">
                    If you have comments or questions please reach out below.
                </Text>
            </Stack>
            <Box minW={'full'}>
                <FormDataProvider>
                    <SubscribeForm onClose={onClose} showSubmitText={true} showTextarea={true}/>
                </FormDataProvider>
            </Box>
        </Stack>
    </Container>
)