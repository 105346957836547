import React from "react";
import {Newsletter} from "../components/Newsletter/Newsletter";
import {NavbarWithPopoverOnAccent} from "../components/NavbarWithPopoverOnAccent/NavbarWithPopoverOnAccent";
import {useDisclosure} from "@chakra-ui/react";

export const Contact = () => {
    const {onClose} = useDisclosure()
    return (
        <>
            <NavbarWithPopoverOnAccent/>
            <Newsletter onClose={onClose}/>
        </>
    )
}