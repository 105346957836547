import React from "react";
import {Box} from "@chakra-ui/react";
import {NavbarWithPopoverOnAccent} from "../components/NavbarWithPopoverOnAccent/NavbarWithPopoverOnAccent";
import {BlogWithHeroImage} from "../components/BlogWithHeroImage/App";

export const Blog = ()=>{
    return (
        <Box>
            <NavbarWithPopoverOnAccent/>
            <BlogWithHeroImage/>
        </Box>
    )
}