import React from "react";
import {Box, ButtonGroup, Container, HStack,} from '@chakra-ui/react'
import {MobileDrawer} from "./MobileDrawer";
import {Logo} from "./Logo";

export const NavbarWithPopoverOnAccent = ({overlay}: { overlay?: boolean }) => (
    <Box as="section" position={'absolute'} color={overlay ? 'white' : 'initial'} zIndex={1}>
        <Box borderBottomWidth="1px" bg="transparent" position="relative" zIndex="tooltip"
             borderBottom={overlay ? 'none' : 'initial'}>
            <Container py="4" minW={'full'}>
                <HStack justify="space-between" spacing="8">
                    <HStack spacing="10">
                        <HStack spacing="3">
                            <MobileDrawer/>
                            <Logo/>
                        </HStack>
                        <ButtonGroup
                            size="lg"
                            variant="text.accent"
                            colorScheme="gray"
                            spacing="8"
                            display={{base: 'none', lg: 'flex'}}
                        >
                            {/*<Button>About</Button>*/}
                            {/*<Button>Contact</Button>*/}
                            {/*<Button as={Link} href={'/'}>Home</Button>*/}
                            {/*<Button as={Link} href={'/blog'}>Blog</Button>*/}
                            {/*<Button as={Link} href={'/contact'}>Contact</Button>*/}
                        </ButtonGroup>
                    </HStack>
                    <HStack spacing={{base: '2', md: '4'}}>

                    </HStack>
                </HStack>
            </Container>
        </Box>
    </Box>
)
