import {
    Box,
    Button,
    Container,
    Divider,
    Heading,
    Link,
    ListItem,
    Stack,
    Text,
    UnorderedList,
    useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import {NavbarWithPopoverOnAccent} from "../components/NavbarWithPopoverOnAccent/NavbarWithPopoverOnAccent";
import {ArrowLeftIcon} from "@chakra-ui/icons";

export const AllergiesHealthySleep = ({title, excerpt}: { title: string, excerpt: string }) => {
    return (
        <Box bg="white">
            <NavbarWithPopoverOnAccent/>
            <Box bg="blue.800" color="white">
                <Container pt={{base: '16', md: '24'}} pb={{base: '32', md: '32'}} maxW={'7xl'}>
                    <Stack spacing={{base: '8', md: '10'}} align="center">
                        <Stack spacing={{base: '4', md: '6'}} textAlign="center" mx={'auto'}>
                            <Stack spacing="4">
                                <Heading size={{base: '2xl', md: '4xl'}}>{title}</Heading>
                            </Stack>
                            <Text fontSize={{base: 'xl', md: 'xl'}} maxW="2xl" mx={'auto'}>
                                {excerpt}
                            </Text>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
            <Box>
                <Container pt={{base: '16', md: '24'}} pb={{base: '32', md: '32'}} maxW={'7xl'}>
                    <Stack spacing="8">
                        <Stack
                            spacing="5"
                            lineHeight="1.75"
                            maxW="7xl"
                            color={useColorModeValue('blackAlpha.800', 'whiteAlpha.800')}
                        >
                            <Text fontSize="lg" mt={4}>
                                Nighttime allergies can turn what should be a restful escape into a struggle for
                                comfort. For those who suffer from allergies, the bedroom can often exacerbate symptoms.
                                A Bed Divider, however, can be a transformative addition to your sleep environment,
                                creating an allergen-reduced space. This post delves into how a Bed Divider can
                                contribute to a more comfortable and uninterrupted night's sleep for allergy sufferers.
                            </Text>
                            <Divider my={6}/>
                            <Heading as="h2" size="lg" mb={4}>
                                The Challenge of Sleeping with Allergies
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                Allergies at night can disrupt sleep due to symptoms like sneezing, coughing, and nasal
                                congestion. These interruptions can prevent the deep, restful sleep needed for the body
                                to recover and rejuvenate.
                            </Text>
                            <Heading as="h2" size="lg" mb={4}>
                                How a Bed Divider Can Help
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                A Bed Divider acts as a physical barrier, reducing the spread of allergens such as dust,
                                pet dander, and pollen from one side of the bed to the other. This separation is crucial
                                for allergy sufferers who share their bed with a partner, especially if the partner is
                                not an allergy sufferer and may inadvertently bring allergens to bed.
                            </Text>
                            <UnorderedList mb={4}>
                                <ListItem>Creates an allergen-reduced zone</ListItem>
                                <ListItem>Minimizes cross-contamination of allergens</ListItem>
                                <ListItem>Supports a cleaner, healthier sleeping environment</ListItem>
                            </UnorderedList>
                            <Heading as="h2" size="lg" mb={4}>
                                Choosing the Right Bed Divider for Allergy Sufferers
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                When selecting a Bed Divider for allergy relief, consider materials that are
                                hypoallergenic and easy to clean. Dividers with washable covers or made from non-fabric
                                materials can be particularly beneficial.
                            </Text>
                            <UnorderedList mb={4}>
                                <ListItem>Opt for hypoallergenic materials</ListItem>
                                <ListItem>Look for easy-to-clean designs</ListItem>
                                <ListItem>Consider breathability for comfort</ListItem>
                            </UnorderedList>
                            <Heading as="h2" size="lg" mb={4}>
                                Additional Tips for Allergy-Friendly Sleep
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                Along with using a Bed Divider, there are other steps you can take to minimize allergies
                                at night, such as using hypoallergenic bedding, maintaining regular cleaning schedules,
                                and using air purifiers to keep the air in your bedroom clean.
                            </Text>
                            <UnorderedList mb={4}>
                                <ListItem>Use hypoallergenic pillows and bedding</ListItem>
                                <ListItem>Maintain a regular cleaning routine</ListItem>
                                <ListItem>Consider an air purifier for your bedroom</ListItem>
                            </UnorderedList>
                            <Heading as="h2" size="lg" mb={4}>
                                The Overall Benefits of an Allergy-Friendly Sleep Environment
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                By creating an allergy-friendly sleep environment with the help of a Bed Divider, you
                                can enjoy deeper, more restful sleep. This improvement in sleep quality can lead to
                                better overall health, improved mood, and enhanced daily performance.
                            </Text>
                        </Stack>
                    </Stack>
                    <Button as={Link} href={'/blog'} leftIcon={<ArrowLeftIcon/>} mt={5} bg={'blue.700'} color={'white'}
                            _hover={{bg: 'blue.700', textDecoration: 'none'}}>Back to Blog</Button>
                </Container>
            </Box>
        </Box>
    )
}