import {
    Box,
    Button,
    Container,
    Divider,
    Heading,
    Link,
    ListItem,
    Stack,
    Text,
    UnorderedList,
    useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import {NavbarWithPopoverOnAccent} from "../components/NavbarWithPopoverOnAccent/NavbarWithPopoverOnAccent";
import {ArrowLeftIcon} from "@chakra-ui/icons";

export const EmbracingQuietNights = ({title, excerpt}: { title: string, excerpt: string }) => {
    return (
        <Box bg="white">
            <NavbarWithPopoverOnAccent/>
            <Box bg="blue.800" color="white">
                <Container pt={{base: '16', md: '24'}} pb={{base: '32', md: '32'}} maxW={'7xl'}>
                    <Stack spacing={{base: '8', md: '10'}} align="center">
                        <Stack spacing={{base: '4', md: '6'}} textAlign="center" mx={'auto'}>
                            <Stack spacing="4">
                                <Heading size={{base: '2xl', md: '4xl'}}>{title}</Heading>
                            </Stack>
                            <Text fontSize={{base: 'xl', md: 'xl'}} maxW="2xl" mx={'auto'}>
                                {excerpt}
                            </Text>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
            <Box>
                <Container pt={{base: '16', md: '24'}} pb={{base: '32', md: '32'}} maxW={'7xl'}>
                    <Stack spacing="8">
                        <Stack
                            spacing="5"
                            lineHeight="1.75"
                            maxW="7xl"
                            color={useColorModeValue('blackAlpha.800', 'whiteAlpha.800')}
                        >
                            <Text fontSize="lg" mt={4}>
                                For many of us, a good night’s sleep is often elusive. Light sleepers, or those sharing
                                their bed with a snoring partner, know this all too well. However, a simple solution
                                lies in an innovative product – the Bed Divider. Designed to create a barrier that
                                reduces noise disturbances, a Bed Divider can be a game-changer for your sleep quality.
                                In this post, we’ll explore how embracing a Bed Divider can lead to quieter, more
                                restful nights.
                            </Text>
                            <Divider my={6}/>
                            <Heading as="h2" size="lg" mb={4}>
                                The Science Behind Sound and Sleep
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                Before delving into the solution, it’s essential to understand the impact of noise on
                                sleep. Sound waves, particularly the inconsistent ones like snoring, can disrupt the
                                sleep cycle, preventing you from reaching the deep, restorative stages of sleep. This
                                disruption not only affects the quantity but also the quality of your sleep, leading to
                                fatigue and reduced cognitive function.
                            </Text>
                            <Heading as="h2" size="lg" mb={4}>
                                Enter the Bed Divider
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                The Bed Divider offers a practical and stylish solution. It’s a physical barrier, often
                                made from sound-absorbing materials, that sits between sleepers. This barrier
                                significantly diminishes the intrusion of unwanted sounds, such as snoring, allowing for
                                a more uninterrupted sleep experience.
                            </Text>
                            <UnorderedList mb={4}>
                                <ListItem>Reduces the intensity of noise disturbances</ListItem>
                                <ListItem>Creates a personal, tranquil sleep environment</ListItem>
                                <ListItem>Enhances overall sleep quality</ListItem>
                            </UnorderedList>
                            <Heading as="h2" size="lg" mb={4}>
                                The Benefits of a Quieter Sleep Environment
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                A quieter sleeping environment is not just about less noise; it’s about creating a space
                                conducive to relaxation and rejuvenation. With a Bed Divider, you can expect:
                            </Text>
                            <UnorderedList mb={4}>
                                <ListItem>Better Sleep Quality: By minimizing disruptions, you enter deeper sleep
                                    stages, essential for physical and mental recovery.</ListItem>
                                <ListItem>Improved Relationship: Snoring can be a source of tension in relationships. A
                                    Bed Divider reduces this strain by allowing both partners to sleep
                                    peacefully.</ListItem>
                                <ListItem>Personal Space: It offers a sense of individual space, which can be comforting
                                    and beneficial for overall well-being.</ListItem>
                            </UnorderedList>
                            <Heading as="h2" size="lg" mb={4}>
                                Choosing the Right Bed Divider
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                When selecting a Bed Divider, consider the material, size, and ease of use. Opt for
                                dividers made from sound-absorbing materials like dense foam or upholstered fabric. Size
                                matters too – ensure it’s tall enough to create an effective barrier but not so large as
                                to dominate your bed space.
                            </Text>
                            <Heading as="h2" size="lg" mb={4}>
                                Installation and Maintenance
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                Most Bed Dividers are designed for easy installation. They’re lightweight and can be set
                                up or removed as needed, with minimal effort. Maintenance is typically straightforward –
                                many are covered in washable materials, making them easy to keep clean and hygienic.
                            </Text>
                            <Heading as="h2" size="lg" mb={4}>
                                Embrace the Silence
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                In conclusion, if you’re seeking a solution for a more peaceful night’s sleep, a Bed
                                Divider could be your answer. It’s an investment in your health, your relationship, and
                                your well-being. Say goodbye to sleepless nights and hello to serene slumbers with the
                                addition of a Bed Divider to your bedroom.
                            </Text>
                        </Stack>
                    </Stack>
                    <Button as={Link} href={'/blog'} leftIcon={<ArrowLeftIcon/>} mt={5} bg={'blue.700'} color={'white'}
                            _hover={{bg: 'blue.700', textDecoration: 'none'}}>Back to Blog</Button>
                </Container>
            </Box>
        </Box>
    )
}