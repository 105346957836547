import * as React from "react";
import {Helmet} from "react-helmet";
// @ts-ignore
import metaImage from '../../assets/takingitslow1.webp';
import {useLocation} from "react-router-dom";

// Define the type for the props that you expect
interface SEOProps {
    title?: string;
}

const SEO: React.FC<SEOProps> = ({title}) => {
    let url = '';

    // TypeScript-safe check for window object
    if (typeof window !== 'undefined') {
        url = window.location.href;
    }

    // Updated to a TypeScript arrow function with explicit return type
    const stripTrailingSlash = (str: string): string => {
        return str.replace(/\/$/, ''); // Replace trailing slash with an empty string
    };

    const location = useLocation();
    const cUrl = `https://www.joinsultry.com${location.pathname}`;
    const canonicalUrl = cUrl.replace(/\/?$/, '/');


    return (
        <Helmet htmlAttributes={{lang: "en"}} title={`${title ? title : 'Sultry'}`}>
            <meta name="description"
                  content={'Sultry, a unique dating platform dedicated to men with E.D. seeking meaningful connections. Discover a community where patience, understanding, and emotional bonds are valued. Experience dating at a pace that\'s right for you. Connect with empathetic partners ready for a journey of respect and deep connection.'}/>
            <meta name="image" content={metaImage}/>

            {/* Facebook Card */}
            <meta property="og:url" content={'www.joinsultry.com'}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={`Sultry | Compassionate Dating for Men`}/>
            <meta property="og:description"
                  content={'Sultry, a unique dating platform dedicated to men with E.D. seeking meaningful connections. Discover a community where patience, understanding, and emotional bonds are valued. Experience dating at a pace that\'s right for you. Connect with empathetic partners ready for a journey of respect and deep connection.'}/>
            <meta property="og:image" content={metaImage}/>
            <meta property="og:image:width" content="400"/>
            <meta property="og:image:height" content="300"/>

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image"/>
            {/* Uncomment and fill in the values if you have them */}
            {/*<meta name="twitter:creator" content={twitterUsername}/>*/}
            {/*<meta name="twitter:title" content={siteTitle}/>*/}
            {/*<meta name="twitter:description" content={siteDesc}/>*/}
            <meta name="twitter:image" content={`${stripTrailingSlash(url)}${metaImage}`}/>
            <link rel="canonical" href={canonicalUrl}/>
        </Helmet>
    );
}

export default SEO;
