import {extendTheme, StyleFunctionProps} from '@chakra-ui/react'
import '@fontsource/herr-von-muellerhoff';
import '@fontsource-variable/cormorant';
import '@fontsource/bebas-neue';
import '@fontsource-variable/roboto-serif';
import '@fontsource/corinthia';
import '@fontsource/dm-serif-text';
import '@fontsource/barlow';

const theme = extendTheme({
    components: {
        Button: {
            baseStyle: {
                // borderRadius: 0
            }
        }
    },
    fonts: {
        body: `'Barlow', sans-serif`,
        heading: `'Barlow', sans-serif`,
        color: '#fff'
    },
    backgroundColor: '#000',
    colors: {
        backgroundColor: '#000',
        color: '#fff',
        transparent: "transparent",
        black: "#000",
        white: "#fff",
        gray: {
            50: "#f7fafc",
            900: "#171923",
        },
    },
    styles: {
        global: (props: StyleFunctionProps) => ({
            body: {
                color: '#fff',
                bg: '#000',
            },
        }),
    },
})

export default theme