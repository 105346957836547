import React from "react";
import {
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel, Hide,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Select,
    Stack,
    Textarea,
    useColorModeValue
} from '@chakra-ui/react';
import {useFormData} from "../../context/formDataContext";
import {EmailIcon} from "@chakra-ui/icons";
import {BiUser} from "react-icons/bi";

export const SubscribeForm = ({onClose, color, size, showTextarea, showSubmitText}: {
    onClose: any,
    color?: string,
    size?: string,
    showTextarea?: boolean
    showSubmitText?: boolean
}) => {
    const {updateFormData} = useFormData();
    const mode = useColorModeValue('blue.500', 'blue.700')
    const handleSubmit = (event: any) => {
        event.preventDefault();

        const myForm = event.target;
        const formData = new FormData(myForm);

        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            // @ts-ignore
            body: new URLSearchParams(formData).toString(),
        })
            .then(() => {
                console.log("Form successfully submitted")
                onClose();
            })
            .catch((error) => alert(error));
    };

    const handleInputChange = (event: any): void => {
        const {name, value} = event.target;
        updateFormData(name, value); // Update the shared form data
    };

    return (
        <form name="contact" method="POST" action="/?index" data-netlify="true" data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact"/>
            <Flex
                gap="5"
                px={{
                    base: '4',
                    md: '6',
                }}
                py={{
                    base: '5',
                    md: '6',
                }}
                direction={{
                    base: 'column',
                    md: 'row',
                }}
            >
                <Stack
                    spacing="4"
                    width={{base: 'full', md: '50%'}}
                >
                    <FormControl isRequired>
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                                <EmailIcon color='gray.300' pos={'relative'} top={'5px'}/>
                            </InputLeftElement>
                            <Input id="email" type="email" name="email" required size="lg" focusBorderColor={mode}
                                   borderColor={'gray.400'} fontSize="md" style={{paddingLeft: '2rem'}}
                                   onChange={handleInputChange}/>
                        </InputGroup>
                    </FormControl>
                    <Flex gap={5}>
                        <FormControl isRequired>
                            <FormLabel htmlFor="age">Age</FormLabel>
                            <Input id="age" type="number" name="age" required size="lg" focusBorderColor={mode}
                                   borderColor={'gray.400'} fontSize="md" onChange={handleInputChange}/>
                        </FormControl>

                        <FormControl isRequired>
                            <FormLabel htmlFor="zipCode">Zip</FormLabel>
                            <Input id="zipCode" type="text" name="zipCode" size="lg" focusBorderColor={mode}
                                   borderColor={'gray.400'} fontSize="md" onChange={handleInputChange}/>
                        </FormControl>
                    </Flex>
                    <Flex gap={5}>
                        <FormControl isRequired>
                            <FormLabel htmlFor="gender">Gender</FormLabel>
                            <Select id="gender" name="gender" placeholder="Select gender" size="lg"
                                    focusBorderColor={mode}
                                    borderColor={'gray.400'} fontSize="md" onChange={handleInputChange}>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="non-binary">Non-binary</option>
                            </Select>
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel htmlFor="sexual-orientation"><Hide below='md'>Sexual </Hide>Orientation</FormLabel>
                            <Select id="sexual-orientation" name="sexualOrientation" placeholder="Select orientation"
                                    size="lg"
                                    focusBorderColor={mode} borderColor={'gray.400'} fontSize="md"
                                    onChange={handleInputChange}>
                                <option value="heterosexual">Heterosexual</option>
                                <option value="homosexual">Gay</option>
                                <option value="bisexual">Bisexual</option>
                            </Select>
                        </FormControl>
                    </Flex>
                    <FormControl>
                        <FormLabel htmlFor="userName">User Name</FormLabel>
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                                <Icon as={BiUser} color='gray.300' pos={'relative'} top={'5px'}/>
                            </InputLeftElement>
                            <Input id="userName" type="text" name="userName" size="lg" focusBorderColor={mode}
                                   borderColor={'gray.400'} fontSize="md" style={{paddingLeft: '2rem'}}
                                   onChange={handleInputChange}/>
                        </InputGroup>
                    </FormControl>
                </Stack>
                <Stack
                    spacing="4"
                    width={{base: 'full', md: '50%'}}
                >
                    <FormControl>
                        <FormLabel htmlFor="about">Tell us about yourself</FormLabel>
                        <Textarea id="about" name="about" size="lg" focusBorderColor={mode}
                                  borderColor={'gray.400'} fontSize="md"
                                  placeholder="Optional bio... (can change this later)"
                                  onChange={handleInputChange}/>
                    </FormControl>

                    <FormControl>
                        <FormLabel htmlFor="expectations">Your ideal partner and connection</FormLabel>
                        <Textarea id="expectations" name="expectations" size="lg" focusBorderColor={mode}
                                  borderColor={'gray.400'} fontSize="md"
                                  placeholder="Describe your ideal partner... (can change this later)"
                                  onChange={handleInputChange}/>
                    </FormControl>
                </Stack>
            </Flex>
            <Flex
                py="4"
                px={{
                    base: '4',
                    md: '6',
                }}
                direction={'column'}
                gap={5}
            >
                <FormControl textAlign={'left'}>
                    <Stack spacing={2}>
                        <Checkbox name="agreements" required alignItems={'center'}>
                            I agree to uphold the values of respect, patience, and understanding within the Sultry
                            community.
                        </Checkbox>

                    </Stack>
                </FormControl>
                <Button
                    type="submit"
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="md"
                    colorScheme="blue"
                    bg={'black'}
                    border={'1px solid #fff'}
                    size="lg"
                    rounded="full"
                >
                    Apply Now
                </Button>
            </Flex>
        </form>
    );
};
