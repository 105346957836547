export const jobListings = [
    {
        "title": "Is anyone else scared to date because of PIED?",
        "description": "Many individuals feel apprehensive about dating due to P.I.E.D., fearing judgment or misunderstanding. Sultry provides a supportive community where such concerns are understood and shared, encouraging open and honest connections."
    },
    {
        "title": "Should I tell my partner about my PIED?",
        "description": "Open communication is key in any relationship. Discussing P.I.E.D. with your partner can build trust and understanding. Sultry advocates for honesty and patience in relationships, helping you navigate these conversations compassionately."
    },
    {
        "title": "How do I date or bring up having P.I.E.D. or psychologically induced ED?",
        "description": "Starting conversations about P.I.E.D. can be challenging. Approach the topic with honesty and when you feel comfortable. Sultry offers resources and advice on how to have these discussions in a respectful and understanding manner."
    },
    {
        "title": "Can P.I.E.D. or psychologically induced ED be cured?",
        "description": "P.I.E.D. is often treatable with the right approach, including therapy, lifestyle changes, and support. Sultry emphasizes the importance of holistic wellbeing, providing tools and resources to aid in recovery and promote healthy relationships."
    },
    {
        "title": "Does porn cause ED?",
        "description": "Excessive pornography consumption can be a contributing factor to ED, especially P.I.E.D. Sultry encourages understanding the impact of porn on sexual health and offers guidance for those seeking to manage its effects on relationships."
    },
    {
        "title": "What does porn do to your brain?",
        "description": "Porn can affect the brain's perception of real-life intimacy, potentially leading to issues like P.I.E.D. Sultry's community and resources help in understanding and mitigating these effects for healthier sexual relationships."
    },
    {
        "title": "How to recover from P.I.E.D?",
        "description": "Recovery from P.I.E.D. involves a combination of psychological therapy, lifestyle adjustments, and supportive relationships. Sultry provides a platform for connecting with understanding partners and accessing resources for recovery."
    },
    {
        "title": "What are the signs of P.I.E.D.?",
        "description": "Signs of P.I.E.D. include difficulty achieving or maintaining an erection and reduced sexual desire, often linked to pornography use. Sultry offers insights and advice on identifying and addressing these symptoms."
    },
    {
        "title": "How can I support a partner with P.I.E.D.?",
        "description": "Supporting a partner with P.I.E.D. involves understanding, patience, and open communication. Sultry guides members on how to be empathetic partners, fostering a nurturing environment for shared growth and healing."
    },
    {
        "title": "Are there community support groups for P.I.E.D.?",
        "description": "Yes, there are various support groups, including Sultry's community, where individuals can share experiences and advice on dealing with P.I.E.D., fostering a supportive network for healing and understanding."
    }
]

export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
        infer ElementType
    >
    ? ElementType
    : never

export type JobListing = ElementType<typeof jobListings>
