import {
    Box,
    Button,
    Container,
    Divider,
    Heading,
    Link,
    ListItem,
    Stack,
    Text,
    UnorderedList,
    useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import {NavbarWithPopoverOnAccent} from "../components/NavbarWithPopoverOnAccent/NavbarWithPopoverOnAccent";
import {ArrowLeftIcon} from "@chakra-ui/icons";

export const SleepHygiene101 = ({title, excerpt}: { title: string, excerpt: string }) => {
    return (
        <Box bg="white">
            <NavbarWithPopoverOnAccent/>
            <Box bg="blue.800" color="white">
                <Container pt={{base: '16', md: '24'}} pb={{base: '32', md: '32'}} maxW={'7xl'}>
                    <Stack spacing={{base: '8', md: '10'}} align="center">
                        <Stack spacing={{base: '4', md: '6'}} textAlign="center" mx={'auto'}>
                            <Stack spacing="4">
                                <Heading size={{base: '2xl', md: '4xl'}}>{title}</Heading>
                            </Stack>
                            <Text fontSize={{base: 'xl', md: 'xl'}} maxW="2xl" mx={'auto'}>
                                {excerpt}
                            </Text>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
            <Box>
                <Container pt={{base: '16', md: '24'}} pb={{base: '32', md: '32'}} maxW={'7xl'}>
                    <Stack spacing="8">
                        <Stack
                            spacing="5"
                            lineHeight="1.75"
                            maxW="7xl"
                            color={useColorModeValue('blackAlpha.800', 'whiteAlpha.800')}
                        >
                            <Text fontSize="lg" mt={4}>
                                Sleep hygiene is crucial for our overall health and well-being. In this post, we delve
                                into how incorporating a Bed Divider into your sleep routine can revolutionize the way
                                you rest. From creating a structured sleep environment to ensuring uninterrupted
                                slumber, a Bed Divider offers numerous unexpected benefits for enhancing your sleep
                                hygiene and, consequently, your overall health.
                            </Text>
                            <Divider my={6}/>
                            <Heading as="h2" size="lg" mb={4}>
                                What is Sleep Hygiene?
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                Sleep hygiene refers to the practices and habits that are conducive to sleeping well on
                                a regular basis. It includes aspects such as the sleeping environment, bedtime routines,
                                and daily lifestyle choices that can significantly impact the quality of your sleep.
                            </Text>
                            <Heading as="h2" size="lg" mb={4}>
                                The Impact of a Structured Sleep Environment
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                A well-structured sleep environment is a cornerstone of good sleep hygiene. It involves
                                creating a space that promotes relaxation and minimizes sleep disturbances. This is
                                where a Bed Divider can play a crucial role.
                            </Text>
                            <UnorderedList mb={4}>
                                <ListItem>Helps define personal sleep space</ListItem>
                                <ListItem>Reduces disturbances from partner movements or sounds</ListItem>
                                <ListItem>Creates a more controlled and calming sleep environment</ListItem>
                            </UnorderedList>
                            <Heading as="h2" size="lg" mb={4}>
                                Enhancing Sleep Quality with a Bed Divider
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                By incorporating a Bed Divider into your sleep routine, you can achieve a higher quality
                                of sleep. It ensures that your personal sleep space is preserved, allowing for a deeper,
                                more restful night's sleep.
                            </Text>
                            <UnorderedList mb={4}>
                                <ListItem>Improves the ability to fall asleep faster</ListItem>
                                <ListItem>Helps in achieving deeper sleep stages</ListItem>
                                <ListItem>Contributes to feeling more refreshed upon waking</ListItem>
                            </UnorderedList>
                            <Heading as="h2" size="lg" mb={4}>
                                Choosing the Right Bed Divider
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                When selecting a Bed Divider, consider factors like material, height, and ease of
                                installation. Opt for a divider that aligns with your specific sleep needs and bedroom
                                aesthetics.
                            </Text>
                            <UnorderedList mb={4}>
                                <ListItem>Material choice for comfort and functionality</ListItem>
                                <ListItem>Appropriate height for effective separation</ListItem>
                                <ListItem>Design that complements your bedroom decor</ListItem>
                            </UnorderedList>
                            <Heading as="h2" size="lg" mb={4}>
                                The Broader Benefits of Improved Sleep Hygiene
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                Good sleep hygiene, supported by tools like a Bed Divider, extends beyond just better
                                sleep. It can lead to improved mental and physical health, better mood regulation, and
                                enhanced daily performance.
                            </Text>
                            <UnorderedList mb={4}>
                                <ListItem>Promotes overall physical health</ListItem>
                                <ListItem>Supports mental well-being and cognitive function</ListItem>
                                <ListItem>Improves mood and energy levels throughout the day</ListItem>
                            </UnorderedList>
                            <Heading as="h2" size="lg" mb={4}>
                                Embracing Better Sleep Habits
                            </Heading>
                            <Text fontSize="md" mb={4}>
                                In conclusion, integrating a Bed Divider into your sleep routine is a simple yet
                                effective step towards better sleep hygiene. By creating a more structured and restful
                                sleep environment, you set the stage for enhanced health and well-being. Embrace the
                                change and enjoy the journey towards healthier, more restorative sleep.
                            </Text>
                        </Stack>
                    </Stack>
                    <Button as={Link} href={'/blog'} leftIcon={<ArrowLeftIcon/>} mt={5} bg={'blue.700'} color={'white'}
                            _hover={{bg: 'blue.700', textDecoration: 'none'}}>Back to Blog</Button>
                </Container>
            </Box>
        </Box>
    )
}