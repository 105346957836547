import {AccordionButton, AccordionItem, AccordionPanel, Stack, Text,} from '@chakra-ui/react'
import {JobListing} from './data'

export const JobPosting = (props: JobListing) => {
    const {title, description} = props
    return (
        <AccordionItem py="4">
            <AccordionButton gap={4} px="0" textAlign={'left'}>
                <Text as="h2" fontWeight="semibold" textStyle="xl">
                    {title}
                </Text>
            </AccordionButton>
            <AccordionPanel px="0">
                <Stack spacing={{base: '6', md: '8'}}>
                    <Stack spacing={{base: '4', md: '5'}}>
                        <Text color="fg.muted">{description}</Text>
                    </Stack>
                </Stack>
            </AccordionPanel>
        </AccordionItem>
    )
}
