import {Modal, ModalBody, ModalContent, ModalOverlay, Stack, Text,} from '@chakra-ui/react'
import {SubscribeForm} from './SubscribeForm'
import {FormDataProvider} from "../../context/formDataContext";

export const PopupWithDiscount = ({isOpen, onClose, color, size}: {
    isOpen: boolean,
    onClose: any,
    color: string,
    size: string
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="5xl"
            // `trapFocus` and `blockScrollOnMount` are only switched off so that the preview works properly.
            blockScrollOnMount={false}
            trapFocus={false}
        >
            <ModalOverlay/>
            <ModalContent borderRadius="2xl" mx="4" bg={'#000'} border={'1px solid #333'} mt={5}>
                <ModalBody>
                    <Stack
                        maxW="full"
                        mx="auto"
                        py={{base: '12', md: '12'}}
                        spacing={{base: '6', md: '5'}}
                    >
                        {/*<Logo/>*/}
                        <Stack spacing="3" textAlign="center">
                            {/*<Text fontSize="lg">Interested our product?</Text>*/}
                            <Text
                                fontWeight="extrabold"
                                fontSize={{base: '2xl', md: '3xl'}}
                                mb={2} pb={0}
                                lineHeight={'40px'}
                            >
                                Join Our Exclusive Community
                            </Text>
                            <Text fontSize="lg" mb={0}>
                                We're thrilled you're considering becoming a part of Sultry. We carefully curate our
                                community to ensure a safe, supportive, and empathetic environment for all our members.
                                Please fill in your details below to apply.
                            </Text>
                            <FormDataProvider>
                                <SubscribeForm onClose={onClose} color={color} size={size}/>
                            </FormDataProvider>
                            <Text fontSize="sm">
                                Your information will not be shared with anyone.
                            </Text>
                        </Stack>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
